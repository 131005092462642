@import "../../index.css";

.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.container {
    width: 100%;
    max-width: 1555px;
    margin: 0 auto;
    height: auto;
    padding: 0 2.5rem;
}

.nav__logo {
    flex-shrink: 0;
    display: grid;
    place-items: center;
}

img {
    border-style: none;
}

.navbar {
    width: 100%;
    height: 6rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 999;
}

.navbar__scrolled {
    background-color: #80a5b35b;
    border-bottom: 1px solid var(--bluish);
    -webkit-backdrop-filter: blur(8px) brightness(1) contrast(1) grayscale(0) hue-rotate(0) invert(0) opacity(1) saturate(1) sepia(0);
    backdrop-filter: blur(8px) brightness(1) contrast(1) grayscale(0) hue-rotate(0) invert(0) opacity(1) saturate(1) sepia(0);
}

.nav__logo span {
    color: var(--white1);
    font-family: var(--font-volk);
    padding-top: 15px;
    font-size: 1.3rem;
    margin-left: 10px;
}

ul {
    display: block;
    list-style-type: disc;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0px;
    margin-inline-end: 0px;
    padding-inline-start: 40px;
    unicode-bidi: isolate;
}

li {
    list-style: none;
}

.nav__menu {
    display: flex;
    align-items: center;
    gap: 1.6rem;
    list-style-type: none;
}

.menu__link {
    text-decoration: none;
    color: var(--white1);
    font-size: 1.3rem;
    font-family: var(--font-poppins);
    position: relative;
    width: 100%;

}

.menu__link::before {
    content: "";
    position: absolute;
    width: 100%;
    height: 3px;
    background-color: var(--white1);
    bottom: -.3rem;
    transform-origin: bottom;
    transform: scaleY(0) translateY(-3px);
    transition: .2s ease-in-out;
    border-radius: 1rem;
}

.languages {
    width: 3rem;
    height: 3rem;
    display: grid;
    place-items: center;
    position: relative;
    margin-left: 1.3rem;
    cursor: pointer;
}

.currentLang {
    width: 100%;
    height: 100%;
    padding: .3rem;
    fill: var(--white1);
}



.lang__img {
    width: 100%;
    height: 100%;
    object-fit: contain;
}

.overlay {
    position: absolute;
    opacity: 0;
    top: -200px;
    transition: .15s ease-in;
}

.active {
    top: 50px;
    opacity: 1;
}

.btn {
    width: 25px;
    cursor: pointer;
    display: none;
}

@media screen and (max-width: 1024px) {
    .btn {
        display: block;
    }
}

@media only screen and (max-width: 1024px) {
    .nav__menu {
        display: none;
    }
    .navbar {
        padding: 0 1.3rem
    }
}

@media only screen and (max-width: 768px) {
    .navbar {
        height:5rem;
        padding: 0
    }

    .nav__logo img {
        width: 3rem;
        height: 3rem
    }

    .nav__logo span {
        display: none
    }
}