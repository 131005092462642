.footer {
    padding: 2rem 1rem;
    background-color: var(--bluish);
}

.footer div {
    align-items: start !important;
}

.footer__links {
    text-align: center;
    font-family: var(--font-poppins);
}

.footer__links li {
    justify-content: center;
    gap: 1rem;
    margin: .625rem 0;
    transition: .2s ease;
}

.footer__links li:hover {
    color: var(--white0);
}

.footer div {
    align-items: start !important
}

.footer__links {
    text-align: center;
    font-family: var(--font-poppins)
}

.footer__links li {
    justify-content: center;
    gap: 1rem;
    margin: .625rem 0;
    transition: .2s ease
}

.footer__links li:hover {
    color: var(--white0)
}

.footer__links li:hover .footer__icon {
    filter: invert(1);
    transform: translateY(-3px)
}

.footer__icon {
    width: 22px;
    height: 22px;
    transition: .3s ease
}

.sm__icon {
    width: 24px;
    height: 24px;
    transition: .3s ease
}

.sm__icon:hover {
    filter: invert(1);
    transform: translateY(-3px)
}

.email {
    word-break: break-all
}

@media only screen and (max-width: 1024px) {
    .footer__container {
        display: grid !important;
        grid-template-columns: 1fr 1fr 1fr
    }

    .footer__links:first-child {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
        margin-bottom: 1.25rem
    }
}

@media only screen and (max-width: 768px) {
    .footer__links:first-child {
        grid-row: unset !important;
        grid-column: unset !important
    }

    .footer__container {
        grid-template-columns: 1fr !important
    }

    .footer__links {
        margin: .625rem 0
    }
}