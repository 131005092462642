.payments__wrp {
    justify-content: center !important;
    gap: 8rem;
    padding-bottom: 3rem;
}

.payments__form {
    flex-shrink: 0;
}

.payments__wrp img {
    max-width: 32rem;
    height: 18rem;
    object-fit: contain;
}


.input__wrp {
    width: 100%;
    max-width: 20rem;
    font-family: var(--font-poppins);
    position: relative
}

.input__wrp .label {
    position: absolute;
    z-index: 1;
    top: .9rem;
    left: 1.25rem;
    font-size: 1rem;
    transition: .2s ease-in-out
}

.input__wrp input {
    width: 100%;
    height: 3.5rem;
    padding: 10px;
    font-size: 1rem;
    outline: none;
    color: var(--black) !important;
    font-family: var(--font-poppins);
    border: 1px solid var(--bluish);
    border-radius: .35rem
}

.input__wrp input:focus {
    border-color: var(--bluish)
}

.input__wrp input[type=date] {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    display: block;
    width: 100% !important;
    line-height: 1.5;
    min-width: 100% !important
}

.label.label__active {
    top: -.7rem;
    background-color: #fff;
    padding: 0 .3rem;
    font-size: .825rem;
    color: var(--bluish)
}

@media only screen and (max-width: 1025px) {
    .payments__wrp {
        flex-direction: column;
    }
}

@media only screen and (max-width: 768px) {
    .input__wrp {
        max-width: 100%
    }
}

@media only screen and (max-width: 520px) {
    .input__wrp .label {
        font-size: .9rem !important
    }
}