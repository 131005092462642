:root {
  --white0: #f0fff4;
  /* Eng oq rang, lekin biroz yashil tus qo'shildi */
  --white1: #e6fff0;
  /* Eng och yashil rang */
  --black: #1c2923;
  /* Qoraroq yashil rang */
  --bluish: #69a88c;
  /* Yashil-bug'doy rang */
  --grey: #7f9387;
  /* Ko'mir-yashil rang */
  --font-poppins: "Poppins", sans-serif;
  --font-volk: "Volkhov", serif;
}

:root {
  --white0: #fff;
  --white1: #f8fdff;
  --black: #2d3134;
  --bluish: #80a5b3;
  --grey: #939597;
  --font-poppins: "Poppins", sans-serif;
  --font-volk: "Volkhov", serif
}


* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  scroll-behavior: smooth;
  scroll-padding-top: 8rem;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box
}

body {
  font-family: var(--font-volk);
  font-size: 16px;
  background-color: var(--white1);
}

a {
  text-decoration: none;
  color: inherit;
}

ul,
li {
  list-style: none
}

.container {
  width: 100%;
  max-width: 1555px;
  margin: 0 auto;
  height: auto;
  padding: 0 2.5rem;
}

.centered {
  text-align: center;
}

.overlay {
  position: absolute;
  opacity: 0;
  top: -200px;
  transition: .15s ease-in
}

.flex {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

@media screen and (width < 1025px) {
  .container {
    padding: 0 1rem
  }
}

@media screen and (width < 800px) {
  * {
    scroll-padding-top: 3rem
  }
}

@media only screen and (min-width: 1024px) {
  .show__results__mobile {
    display: none
  }
}

@media only screen and (max-width: 1024px) {
  .payments__wrp {
    flex-direction: column;
    row-gap: 1rem;
    text-align: center
  }

  .payments__wrp .payments__form * {
    margin: .5rem 0
  }

  .payments__wrp img {
    width: 80%
  }

  .payments__wrp br {
    display: none
  }

  .btn__labels {
    justify-content: start;
    gap: .5rem
  }

  .hero {
    padding: 8rem 1.5rem;
    text-align: center
  }

  .search__tours {
    margin: 3.2rem auto 0
  }

  .search__tours form {
    display: grid;
    grid-template-columns: 1fr 1fr;
    place-items: center
  }

  .show__results__mobile {
    margin: 0
  }

  .show__results {
    display: none
  }
}

@media only screen and (max-width: 768px) {
  .payments__wrp img {
    display: none
  }

  .hero {
    display: grid;
    place-items: center;
    padding: 0
  }

  .search__tours {
    margin-top: 2.5rem;
    padding: 1.5rem
  }

  .search__tours form {
    grid-template-columns: 1fr
  }

  .btn__labels {
    flex-wrap: nowrap;
    overflow-x: auto;
    padding: 0 1rem
  }

  .btn__labels .label__btn {
    flex-shrink: 0
  }

  .content__label {
    font-size: .825rem
  }

  .content__title {
    font-size: 2.25rem
  }

  .section__label {
    font-size: 1rem
  }

  .section__title {
    padding: 0 1rem
  }
}

@media only screen and (max-width: 520px) {
  ._content__label_1lnib_26 {
    font-size: 15px
  }

  ._content__title_1lnib_13 {
    margin-top: .825rem;
    font-size: 1.7rem
  }
}

@media only screen and (max-width: 520px) {
  .navigation-wrapper {
    padding: 0 1rem
  }

  .carousel__card {
    padding: .825rem
  }

  .carousel__card .card__title {
    font-size: 1.2rem;
    margin: .625rem 0
  }

  .carousel__card .card__details {
    font-size: .9rem
  }

  .arrow {
    width: 35px;
    height: 35px
  }

  .arrow--left {
    left: -.6rem
  }

  .arrow--right {
    right: -.6rem
  }
}

.page-title {
  font-size: calc(2rem + 2vw);
  color: var(--white1);
  font-family: var(--font-poppins);
  font-weight: 700;
  line-height: 1.2;
  letter-spacing: 1px
}

.section-title {
  font-size: calc(1.25rem + 1vw);
  color: var(--black)
}

.section-label {
  color: var(--bluish);
  font-weight: 400;
  letter-spacing: 2px;
  font-size: 1.2rem;
  margin-bottom: 1.5rem
}

.centered {
  text-align: center
}

.section-title.centered {
  padding: 0 2rem
}

.card-details {
  color: var(--black);
  font-family: var(--font-poppins);
  font-size: 1rem;
  font-weight: 500;
  margin: 1.5rem 0
}

.card-details.gray {
  color: var(--grey);
  margin: 0;
  margin-top: .825rem
}

.card-title {
  color: var(--black);
  font-family: var(--font-poppins);
  font-size: 1.3rem
}

.card-price {
  font-size: 1.225rem;
  color: #5b5f62;
  padding: .5rem 0;
  font-family: var(--font-poppins)
}

@media only screen and (max-width: 1025px) {
  .section-title {
    text-align: center
  }

  .card-title {
    font-size: 1.25rem
  }

  .card-price {
    font-size: 1.1rem
  }
}

@media only screen and (max-width: 768px) {
  .section-label {
    font-size: .825rem
  }

  .page-title {
    font-size: 2.25rem
  }

  .section-title {
    padding: 0 1rem
  }
}

@media only screen and (max-width: 520px) {
  .section-title {
    margin-top: .825rem;
    font-size: 1.7rem
  }
}

.about__layout {
  display: flex;
  justify-content: space-between;
  align-items: center
}

.about__text {
  padding: 0 3rem
}


.about__banner {
  width: 100%;
  max-width: 45rem;
  flex-shrink: 1;
  height: 43rem
}

.about__banner img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center
}

@media only screen and (min-width: 1550px) {
  .about__text {
      padding-left:15rem
  }
}

@media only screen and (max-width: 1024px) {
  .about__layout {
      width:100%;
      flex-direction: column;
      background-image: url(https://bismillah-travel.sirv.com/Images/about-banner.webp);
      padding: 3.5rem 2rem;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center
  }

  .about__text {
      width: 100%;
      max-width: 768px;
      background-color: var(--white0);
      padding: 2.5rem;
      border-radius: 1rem;
      display: flex;
      flex-direction: column;
      align-items: center;
      text-align: center
  }

  .about__banner {
      display: none
  }
}

@media only screen and (max-width: 768px) {
  .cards {
      column-gap:.625rem
  }

  .card {
      padding: .625rem
  }

  .card h1 {
      font-size: 1.25rem
  }
}

@media only screen and (max-width: 520px) {
  .cards {
      grid-template-columns:minmax(5rem,15rem);
      gap: .625rem
  }
}

.btn {
  width: 25px;
  cursor: pointer;
  display: none
}

.btn>span {
  display: block;
  width: 100%;
  box-shadow: 0 2px 10px #0000004d;
  border-radius: 3px;
  height: 2px;
  background: #fff;
  transition: .25s ease-in-out;
  position: relative
}

span+span {
  margin-top: 5px
}

.active span:first-child {
  animation: ease 468ms top forwards
}

.not-active span:first-child {
  animation: ease 468ms top-2 forwards
}

.active span:nth-child(2) {
  animation: ease 468ms scaled forwards
}

.not-active span:nth-child(2) {
  animation: ease 468ms scaled-2 forwards
}

.active span:nth-child(3) {
  animation: ease 468ms bottom forwards
}

.not-active span:nth-child(3) {
  animation: ease 468ms bottom-2 forwards
}
@keyframes top {
  0% {
      top: 0;
      transform: rotate(0)
  }

  50% {
      top: 8px;
      transform: rotate(0)
  }

  to {
      top: 8px;
      transform: rotate(45deg)
  }
}

@keyframes top-2 {
  0% {
      top: 4px;
      transform: rotate(45deg)
  }

  50% {
      top: 4px;
      transform: rotate(0)
  }

  to {
      top: 0;
      transform: rotate(0)
  }
}

@keyframes bottom {
  0% {
      bottom: 0;
      transform: rotate(0)
  }

  50% {
      bottom: 4px;
      transform: rotate(0)
  }

  to {
      bottom: 4px;
      transform: rotate(135deg)
  }
}

@keyframes bottom-2 {
  0% {
      bottom: 10px;
      transform: rotate(135deg)
  }

  50% {
      bottom: 10px;
      transform: rotate(0)
  }

  to {
      bottom: 0;
      transform: rotate(0)
  }
}

@keyframes scaled {
  50% {
      transform: scale(0)
  }

  to {
      transform: scale(0)
  }
}

@keyframes scaled-2 {
  0% {
      transform: scale(0)
  }

  50% {
      transform: scale(0)
  }

  to {
      transform: scale(1)
  }
}