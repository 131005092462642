@import url("../../index.css");

button {
    font-family: var(--font-poppins)
}

.gray--btn {
    padding: .625rem 1rem;
    font-size: .9rem;
    background-color: var(--bluish);
    border: 2px solid var(--bluish);
    border-radius: .25rem;
    color: var(--white0);
    transition: .25s all;
    display: flex;
    justify-content: center;
    align-items: center
}

.btn-icon {
    width: 25px;
    height: 25px;
    object-fit: cover;
    margin-right: .25rem
}

.gray--btn.oval {
    border-radius: 2rem
}

.gray--btn.hoverable:hover {
    background-color: var(--white1);
    color: var(--bluish)
}


@media only screen and (max-width: 1024px) {
    .gray--btn {
        width:100%;
        max-width: 300px
    }
}

@media only screen and (max-width: 520px) {
    .gray--btn {
        width:100%;
        max-width: 250px
    }
}
