@import url("../../index.css");

h1, h2, h3, h4, h5, h6 {
    font-family: var(--font-poppins);
    font-weight: 600;
    line-height: 1.4;
    color: #000;
}

.section-label {
    color: var(--bluish);
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 1.2rem;
    margin-bottom: 1.5rem;
}

.section-title {
    font-size: calc(1.25rem + 1vw);
    color: var(--black);
}