.btn__labels {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    align-items: center;
    column-gap: 1rem;
}

.label__btn {
    background-color: transparent;
    color: var(--black);
    cursor: pointer;
    border: 1px solid var(--bluish);
    padding: .5rem 1rem;
    border-radius: .8rem;
}

.btn__active {
    background-color: var(--bluish);
    color: var(--white0);
}

