.tourprice__card {
    text-align: center;
    max-height: 28rem;
    padding-bottom: 1rem;
}

.tourprice__card .img__wrp {
    width: 100% !important;
    height: 19rem;
    background-color: var(--bluish);
    border-radius: 1rem;
    margin-bottom: .5rem;
}

.card-title {
    color: var(--black);
    font-family: var(--font-poppins);
    font-size: 1.3rem;
}

.card-price {
    font-size: 1.225rem;
    color: #5b5f62;
    padding: .5rem 0;
    font-family: var(--font-poppins);
}

.img__wrp img {
    width: 100%;
    height: 100%;
    border-radius: 1rem;
    object-fit: cover;
    object-position: center
}

.card__date {
    font-size: 1rem;
    font-family: var(--font-poppins);
    color: var(--black);
    color: #5b5f62;
    margin: .625rem 0
}

@media only screen and (max-width: 1200px) {
    .tourprice__card {
        height:auto
    }

    .tourprice__card .img__wrp {
        height: 12rem
    }
}