.navigation-wrapper {
    width: 90%;
    max-width: 1555px;
    padding: 0 3rem;
    margin: 2rem auto;
    position: relative;
}
.carousel__card {
    text-align: center;
    padding: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 1.5rem;
    border: 1px solid var(--bluish);
}

.card__img {
    width: calc(3rem + 5vw);
    height: calc(3rem + 5vh);
    object-fit: contain;
}

.carousel__card .card__title {
    font-size: 1.5rem;
    text-transform: uppercase;
    color: var(--bluish);
    margin: 1rem 0;
}

.carousel__card .card__details {
    color: var(--black);
    font-family: var(--font-poppins);
    font-size: 1rem;
}

.arrow--left {
    left: -1rem;
}

.arrow--right {
    right: -1rem;
    transform-origin: center;
    transform: rotate(180deg) translateY(15px);
}

.arrow {
    font-size: 2rem;
    display: grid;
    place-items: center;
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    color: var(--black);
    cursor: pointer;
}


.keen-slider:not([data-keen-slider-disabled]) {
    -webkit-touch-callout: none;
    -webkit-tap-highlight-color: transparent;
    align-content: flex-start;
    display: flex;
    overflow: hidden;
    position: relative;
    touch-action: pan-y;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -khtml-user-select: none;
    width: 100%
}

.keen-slider:not([data-keen-slider-disabled]) .keen-slider__slide {
    min-height: 100%;
    overflow: hidden;
    position: relative;
    width: 100%
}

.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-reverse] {
    flex-direction: row-reverse
}

.keen-slider:not([data-keen-slider-disabled])[data-keen-slider-v] {
    flex-wrap: wrap
}
