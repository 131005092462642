.input__wrp {
    width: 100%;
    max-width: 20rem;
    font-family: var(--font-poppins);
    position: relative;
}

.input__wrp input {
    width: 100%;
    height: 3.5rem;
    padding: 10px;
    font-size: 1rem;
    outline: none;
    color: var(--black) !important;
    font-family: var(--font-poppins);
    border: 1px solid var(--bluish);
    border-radius: .35rem;
}

.input__wrp .label {
    position: absolute;
    z-index: 1;
    top: .9rem;
    left: 1.25rem;
    font-size: 1rem;
    transition: .2s ease-in-out;
}

.gray--btn {
    padding: .625rem 1rem;
    font-size: .9rem;
    background-color: var(--bluish);
    border: 2px solid var(--bluish);
    border-radius: .25rem;
    color: var(--white0);
    transition: .25s all;
    display: flex;
    justify-content: center;
    align-items: center;
}

.label.label__active {
    top: -.7rem;
    background-color: var(--white1);
    padding: 0 .3rem;
    font-size: .825rem;
    color: var(--bluish);
}