@import "../../../index.css";

.about__layout {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.about__text {
    padding: 0 3rem;
}

.card-details {
    color: var(--black);
    font-family: var(--font-poppins);
    font-size: 1rem;
    font-weight: 500;
    margin: 1.5rem 0;
}

.cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(8rem, 9rem));
    column-gap: 1rem;
    margin-bottom: 2rem;
}

.card {
    padding: 1rem;
    border: 1px solid var(--bluish);
    display: grid;
    place-items: center;
    border-radius: 1rem;
}

.section-title.centered {
    padding: 0 2rem;
}

.card-details.gray {
    color: var(--grey);
    margin: 0;
    margin-top: .825rem;
}

.about__banner {
    width: 100%;
    max-width: 45rem;
    flex-shrink: 1;
    height: 43rem;
}

.about__banner img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    object-position: center;
}