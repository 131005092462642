.sidebar__menu {
    list-style: none;
    font-family: var(--font-poppins);
    font-size: 18px;
}

.sidebar__wrp {
    width: 100%;
    height: auto;
    min-height: 400px;
    padding: 2rem;
    position: fixed;
    top: -100%;
    background-color: #80a5b35b;
    -webkit-backdrop-filter: blur(8px) brightness(1) contrast(1) grayscale(0) hue-rotate(0) invert(0) opacity(1) saturate(1) sepia(0);
    backdrop-filter: blur(8px) brightness(1) contrast(1) grayscale(0) hue-rotate(0) invert(0) opacity(1) saturate(1) sepia(0);
    z-index: 999;
    transition: .2s ease;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
}

.sidebar__active {
    top: 5rem;
}

.menu__item {
    margin: 1.25rem 0;
    color: var(--white1);
}

.menu__link {
    /* color: red; */
}