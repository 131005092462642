.cards {
    display: grid;
    grid-template-columns: repeat(3, minmax(20rem, 28rem));
    margin: 2.5rem 0;
    gap: 1rem;
}

.card {
    width: 100%;
    max-width: 24rem;
    background-color: var(--white0);
    padding: 1rem 2rem;
    border: 1px solid var(--grey);
    border-radius: 1rem;
    box-shadow: .425rem .2rem 0 var(--bluish);
}

.card-title {
    color: var(--black);
    font-family: var(--font-poppins);
    font-size: 1.3rem;
}

.card p:first-child {
    height: 100px;
}

.card-details {
    color: var(--black);
    font-family: var(--font-poppins);
    font-size: 1rem;
    font-weight: 500;
    margin: 1.5rem 0;
}

.google__wrp {
    display: flex;
    justify-content: start;
    align-items: center;
    margin: 1rem 0;
    gap: .325rem;
}

.star__icon {
    width: 24px;
    height: 24px;
    object-fit: cover;
}

.card__img {
    width: 100%;
}

.view__more {
    font-size: 1rem;
    color: var(--black);
    font-weight: 600
}

@media only screen and (max-width: 1200px) {
    .card {
        padding:1rem
    }
}

@media only screen and (max-width: 1100px) {
    .cards {
        grid-template-columns:repeat(2,1fr)
    }
}

@media only screen and (max-width: 768px) {
    .cards {
        grid-template-columns:1fr
    }

    .section__head {
        flex-direction: column;
        text-align: center
    }

    .section__head h1 {
        margin: 0 0 .625rem
    }

    .card {
        max-width: 100%;
        text-align: center;
        display: grid;
        place-items: center
    }

    .card p:first-child {
        height: auto
    }

    .card p {
        margin: .625rem
    }

    .google__wrp {
        margin: 0
    }
}