@import "../../index.css";

main {
    padding-bottom: 4rem;
}

.hero {
    min-height: 80vh;
    min-height: 80svh;
    background-color: var(--black);
    background-image: url(https://avatars.mds.yandex.net/get-mpic/331398/img_id1510224573147625020.jpeg/orig);
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    padding: 5rem 0 0;
    display: flex;
    place-items: center;
}

.content__label {
    font-size: 1.25rem;
    text-transform: uppercase;
    color: var(--bluish);
    font-family: var(--font-poppins);
    letter-spacing: 2px;
}

.content__title {
    max-width: 72.625rem;
    font-size: calc(2rem + 2vw);
    color: var(--bluish);
    font-family: var(--font-volk);
    font-weight: 700;
    line-height: 1.2;
    text-transform: uppercase;
    letter-spacing: 1px;
}

.content__title span {
    color: var(--white1);
}

section {
    padding: 2rem 0;
}

.section__title {
    font-size: calc(1.25rem + 1vw);
    color: var(--black);
    font-weight: 600;
    text-align: center;
    padding: 0 2rem;
}

.section__label {
    text-align: center;
    color: var(--bluish);
    font-weight: 400;
    letter-spacing: 2px;
    font-size: 1.2rem;
}

.navigation-wrapper {
    width: 90%;
    max-width: 1555px;
    padding: 0 3rem;
    margin: 2rem auto;
    position: relative;
}


.category {}

.we_offer {}

.hero_title {}

.title_label {}

.hero_label {}