.not-active span:first-child {
    animation: ease 468ms top-2 forwards;
}

.btn {
    width: 25px;
    cursor: pointer;
    display: none;
}

.btn>span {
    display: block;
    width: 100%;
    box-shadow: 0 2px 10px #0000004d;
    border-radius: 3px;
    height: 3px;
    background: #fff;
    transition: .25s ease-in-out;
    position: relative;
    margin: 5px;
}

@media screen and (max-width: 1024px) {
    .btn {
        display: block;
    }
}