.gallery__wrp,.gallery__wrp__5 {
    height: 33rem;
    display: grid;
    grid-template-columns: repeat(4,1fr);
    grid-template-rows: 1fr 1fr;
    gap: 1.5rem 1rem;
    margin: 1rem 0
}

.gallery__img {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 1rem;
    padding: 1rem
}

.gallery__img p {
    color: var(--white0);
    margin: 0
}

.img__link {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: end
}

.gallery__img:nth-child(2) {
    grid-column: 2 / 3;
    grid-row: 1 / 3
}

.gallery__img:nth-child(3) {
    grid-column: 3 / 5;
    grid-row: 1
}

.gallery__wrp__5 .gallery__img:last-child {
    grid-column: 3 / 5
}

@media only screen and (max-width: 1200px) {
    .gallery__wrp,.gallery__wrp__5 {
        grid-template-columns:repeat(6,1fr)
    }

    .gallery__img:first-child {
        grid-row: 1 / 2;
        grid-column: 1 / 3
    }

    .gallery__img:nth-child(2) {
        grid-row: 1 / 3;
        grid-column: 3 / 5
    }

    .gallery__img:nth-child(3) {
        grid-row: 1 / 2;
        grid-column: 5 / 7
    }

    .gallery__wrp.gallery__img:nth-child(4):not(.gallery__wrp__5 .gallery__img:nth-child(4)) {
        display: none
    }

    .gallery__wrp__5 .gallery__img:nth-child(4) {
        grid-column: 1/ 3
    }

    .gallery__wrp__5 .gallery__img:last-child {
        grid-column: 5 / 7
    }

    .gallery__img:nth-child(5) {
        grid-column: 1 / 3;
        grid-row: 2 / 3
    }

    .gallery__img:nth-child(6) {
        grid-column: 5 / 7;
        grid-row: 2 / 3
    }
}

@media only screen and (max-width: 768px) {
    .gallery__wrp,.gallery__wrp__5 {
        height:auto;
        grid-template-columns: repeat(4,1fr);
        grid-template-rows: repeat(3,15rem)
    }

    .gallery__img:first-child {
        grid-row: 1 / 2;
        grid-column: 1 / 4
    }

    .gallery__img:nth-child(2) {
        grid-row: 1 / 2;
        grid-column: 4 / 7
    }

    .gallery__img:nth-child(3) {
        grid-row: 2 / 3;
        grid-column: 1 / 3
    }

    .gallery__wrp__5 .gallery__img:nth-child(3) {
        grid-row: 2 / 4;
        grid-column: 1 / 3
    }

    .gallery__wrp__5 .gallery__img:nth-child(4) {
        grid-row: 2 / 3;
        grid-column: 3 / 7
    }

    .gallery__wrp__5 .gallery__img:nth-child(2) {
        grid-row: 1 / 2;
        grid-column: 3 / 7
    }

    .gallery__wrp__5 .gallery__img:first-child {
        grid-row: 1 / 2;
        grid-column: 1 / 3
    }

    .gallery__wrp__5 .gallery__img:last-child {
        grid-row: 3 / 4;
        grid-column: 3 / 7
    }

    .gallery__img:nth-child(4) {
        display: flex;
        grid-row: 2 / 3;
        grid-column: 3 / 7
    }

    .gallery__img:nth-child(5) {
        grid-row: 3 / 4;
        grid-column: 1 / 4
    }

    .gallery__img:last-child {
        grid-row: 3 / 4;
        grid-column: 4 / 7
    }
}

@media only screen and (max-width: 600px) {
    .gallery__wrp,.gallery__wrp__5 {
        grid-template-columns:repeat(2,1fr);
        grid-template-rows: repeat(3,15rem)
    }

    .gallery__img {
        grid-row: unset!important;
        grid-column: unset!important
    }
}

@media only screen and (max-width: 500px) {
    .gallery__wrp,.gallery__wrp__5 {
        grid-template-columns:1fr;
        grid-template-rows: repeat(4,15rem)
    }

    .gallery__img:last-child,.gallery__img:nth-child(5) {
        display: none
    }
}