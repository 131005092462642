.pricecard__wrp {
    display: grid;
    place-items: center;
}

.cards__wrp {
    display: grid;
    grid-template-columns: repeat(4, 1fr);
    gap: 1rem;
    margin: 2.5rem 0
}

@media only screen and (max-width: 1025px) {
    .cards__wrp {
        grid-template-columns: repeat(2, 1fr)
    }

    .pricecard__wrp {
        display: unset;
        place-items: unset;
        padding: .5rem 2rem
    }
}

@media only screen and (max-width: 768px) {
    .pricecard__wrp {
        padding: .5rem
    }
}

@media only screen and (max-width: 600px) {
    .cards__wrp {
        grid-template-columns: 1fr
    }
}